/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * The aws-amplify package, relies on crypto-js, a Node module which uses "global",
 * and this isn't available in the brower (rather, window is in browser); hence we get
 * "Uncaught ReferenceError: global is not defined" in browser console from AWS. This
 * polyfill allows global get put in browser.
 * Solution: https://stackoverflow.com/a/51380307/2213882
 * Other idea: https://github.com/nrwl/nx/issues/3673#issuecomment-721148212
 *
 * TODO: Still have to resolve "Module not found: Error: Can't resolve 'crypto' in 'C:\Users\Somto PC\Code\driverup\node_modules\crypto-js'"
 * Warning in console.
 */
(window as any).global = window;
